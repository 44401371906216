<template>
  <div>
    <div class="mb-4">
      <input
        v-model="search"
        type="text"
        placeholder="Search products..."
        class="p-2 border rounded w-full"
        @input="handleSearch"
      />
    </div>
    <div class="bg-white shadow overflow-hidden sm:rounded-md">
      <ul class="divide-y divide-gray-200">
        <li v-for="product in products" :key="product.id" class="hover:bg-gray-50">
          <div class="px-4 py-4 sm:px-6">
            <div class="flex items-center justify-between">
              <div>
                <h3 class="text-lg font-medium text-indigo-600 truncate">{{ product.name }}</h3>
                <p class="mt-1 text-sm text-gray-500">{{ product.description }}</p>
                <p class="mt-1 text-sm text-gray-500">Image Description: {{ product.image_description }}</p>
                <p class="mt-1 text-sm text-gray-500">Bid: ${{ product.bid }}</p>
                <p class="mt-1 text-sm text-gray-500">Budget: ${{ product.budget }}</p>
                <p class="mt-1 text-sm" :class="product.is_embedded ? 'text-green-500' : 'text-red-500'">
                  {{ product.is_embedded ? 'Embedded' : 'Not Embedded' }}
                </p>
                <div class="mt-2 flex flex-wrap">
                  <img v-for="image in product.images" :key="image" :src="getImageUrl(image)" class="w-24 h-24 object-cover m-1 rounded" />
                </div>
              </div>
              <div class="ml-2 flex-shrink-0 flex">
                <span class="mr-2 text-sm text-gray-500">Live:</span>
                <label class="switch pr-2 mr-2">
                  <input type="checkbox" :checked="product.live" @change="toggleLive(product)">
                  <span class="slider round"></span>
                </label>
                <button @click="editProduct(product)" class="mr-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Edit
                </button>
                <button @click="confirmDelete(product.id)" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                  Delete
                </button>
                <button @click="viewProductAds(product.id)" class="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  View Ads
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="mt-4 flex justify-between">
      <button
        @click="changePage(currentPage - 1)"
        :disabled="currentPage === 1"
        class="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
      >
        Previous
      </button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button
        @click="changePage(currentPage + 1)"
        :disabled="currentPage === totalPages"
        class="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
      >
        Next
      </button>
    </div>

    <!-- Edit Product Modal -->
    <div v-if="showEditProductModal" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Edit Product
                </h3>
                <div class="mt-2">
                  <input v-model="editingProduct.name" type="text" placeholder="Product Name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                  <textarea v-model="editingProduct.description" placeholder="Product Description" rows="3" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
                  <input v-model="editingProduct.link" type="text" placeholder="Product Link" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                  <textarea v-model="editingProduct.image_description" placeholder="Image Description" rows="3" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
                  <input v-model="editingProduct.bid" type="number" step="0.01" placeholder="Bid" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                  <input v-model="editingProduct.budget" type="number" step="0.01" placeholder="Budget" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                  <input @change="handleFileUpload" type="file" multiple accept="image/*" class="mt-1 block w-full">
                  <div class="mt-2 flex flex-wrap">
                    <div v-for="image in editingProduct.images" :key="image" class="relative m-1">
                      <img :src="getImageUrl(image)" class="w-24 h-24 object-cover rounded" />
                      <button @click="deleteImage(editingProduct.id, image)" class="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1">
                        X
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="updateProduct" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm">
              Update
            </button>
            <button @click="showEditProductModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import api from '@/api'
import { debounce } from 'lodash'

export default {
  name: 'ProductList',
  setup() {
    const router = useRouter()
    const products = ref([])
    const currentPage = ref(1)
    const totalPages = ref(1)
    const search = ref('')
    const perPage = ref(10)
    const showEditProductModal = ref(false)
    const editingProduct = reactive({})
    const selectedFiles = ref([])

    const fetchProducts = async () => {
      try {
        const response = await api.get('/products', {
          params: { page: currentPage.value, per_page: perPage.value, search: search.value }
        })
        products.value = response.data.products
        totalPages.value = response.data.pages
      } catch (error) {
        console.error('Error fetching products:', error)
      }
    }

    const debouncedFetchProducts = debounce(() => {
      currentPage.value = 1  // Reset to first page
      fetchProducts()
    }, 300)

    const handleSearch = () => {
      debouncedFetchProducts()
    }


    const toggleLive = async (product) => {
      try {
        const response = await api.put(`/products/${product.id}`, {
          ...product,
          live: !product.live
        })
        if (response.status === 200) {
          product.live = !product.live
        }
      } catch (error) {
        console.error('Error updating product live status:', error)
      }
    }

    const getImageUrl = (filename) => {
      return `${api.defaults.baseURL}/images/${filename}`
    }

    const changePage = (newPage) => {
      currentPage.value = newPage
      fetchProducts()
    }

    const viewProductAds = (productId) => {
      router.push(`/products/${productId}/ads`)
    }

    const editProduct = (product) => {
      Object.assign(editingProduct, product)
      showEditProductModal.value = true
    }

    const updateProduct = async () => {
      try {
        await uploadImages(editingProduct.id)
        const response = await api.put(`/products/${editingProduct.id}`, editingProduct)
        if (response.status === 200) {
          showEditProductModal.value = false
          fetchProducts()
        }
      } catch (error) {
        console.error('Error updating product:', error)
      }
    }

    const uploadImages = async (productId) => {
      if (selectedFiles.value.length === 0) return

      const formData = new FormData()
      for (const file of selectedFiles.value) {
        formData.append('images', file)
      }

      try {
        const response = await api.post(`/products/${productId}/images`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        editingProduct.images = [...editingProduct.images, ...response.data.filenames]
        selectedFiles.value = []
      } catch (error) {
        console.error('Error uploading images:', error)
      }
    }

    const handleFileUpload = (event) => {
      selectedFiles.value = event.target.files
    }

    const deleteImage = async (productId, filename) => {
      try {
        await api.delete(`/products/${productId}/images/${filename}`)
        editingProduct.images = editingProduct.images.filter(img => img !== filename)
      } catch (error) {
        console.error('Error deleting image:', error)
      }
    }

    const confirmDelete = (productId) => {
      if (confirm('Are you sure you want to delete this product?')) {
        deleteProduct(productId)
      }
    }

    const deleteProduct = async (productId) => {
      try {
        await api.delete(`/products/${productId}`)
        fetchProducts()
      } catch (error) {
        console.error('Error deleting product:', error)
      }
    }

    onMounted(fetchProducts)

    watch(search, () => {
      debouncedFetchProducts()
    })

    return {
      products,
      currentPage,
      totalPages,
      search,
      showEditProductModal,
      editingProduct,
      handleSearch,
      toggleLive,
      getImageUrl,
      changePage,
      viewProductAds,
      editProduct,
      updateProduct,
      handleFileUpload,
      deleteImage,
      confirmDelete,
      deleteProduct
    }
  }
}
</script>

<style scoped>
/* Toggle switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>