<template>
  <div class="min-h-screen bg-gray-100">
    <nav class="bg-white shadow-sm">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex-shrink-0 flex items-center">
              <h1 class="text-lg font-semibold">Aditto</h1>
            </div>
          </div>
          <div class="flex items-center">
            <button @click="goBack" class="mr-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Back to Products
            </button>
            <button @click="logout" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
    <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div class="px-4 py-6 sm:px-0">
        <h2 class="text-2xl font-semibold mb-4">Ads for Product {{ $route.params.productId }}</h2>
        <div class="mb-4 flex justify-between items-center">
          <input
            v-model="search"
            type="text"
            placeholder="Search ads by title, copy, link copy, or URL..."
            class="p-2 border rounded w-2/3"
            @input="handleSearch"
          />
          <div class="flex items-center">
            <label for="sort" class="mr-2">Sort by Quality Score:</label>
            <select
              id="sort"
              v-model="sortOrder"
              @change="handleSortChange"
              class="p-2 border rounded"
            >
              <option value="desc">Highest to Lowest</option>
              <option value="asc">Lowest to Highest</option>
            </select>
          </div>
        </div>
        <div v-if="ads.length > 0" class="bg-white shadow overflow-hidden sm:rounded-md">
          <ul class="divide-y divide-gray-200">
            <li v-for="ad in ads" :key="ad.id" class="p-4">
              <div class="flex justify-between items-start">
                <div class="flex-grow">
                  <p class="mt-1 text-sm text-gray-500">Product URL: <a :href="ad.product_url" target="_blank" class="text-blue-500 hover:underline">{{ ad.product_url }}</a></p>
                  <p class="mt-1 text-sm text-gray-500">Ad Location: <a :href="ad.content.ad_location_url" target="_blank" class="text-blue-500 hover:underline">{{ ad.content.ad_location_url }}</a></p>
                  <p class="mt-1 text-sm text-gray-500">Impressions: {{ ad.impressions }} | Clicks: {{ ad.clicks }}</p>
                  <p class="mt-1 text-sm text-gray-500">Quality Match: {{ ad.content.quality_match.toFixed(4) }}</p>
                  <div class="mt-2">
                    <h4 class="font-medium">Ad Copy:</h4>
                    <p class="text-sm">{{ ad.content.ad_copy }}</p>
                  </div>
                  <div class="mt-2">
                    <h4 class="font-medium">Ad Link Copy:</h4>
                    <p class="text-sm">{{ ad.content.ad_link_copy }}</p>
                  </div>
                </div>
                <div class="ml-4 flex-shrink-0">
                  <img v-if="ad.image_url" :src="ad.image_url" alt="Ad Image" class="w-32 h-32 object-cover rounded">
                  <button @click="confirmDeleteAd(ad.id)" class="inline-flex items-center mt-4 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                  Delete Ad
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-else class="text-center py-4">
          No ads found for the current search criteria.
        </div>
        <div v-if="ads.length > 0" class="mt-4 flex justify-between">
          <button
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
            class="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
          >
            Previous
          </button>
          <span>Page {{ currentPage }} of {{ totalPages }}</span>
          <button
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === totalPages"
            class="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import api from '@/api'
import { debounce } from 'lodash'

export default {
  name: 'ProductAdsView',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const ads = ref([])
    const currentPage = ref(1)
    const totalPages = ref(1)
    const search = ref('')
    const sortOrder = ref('desc')
    const perPage = ref(10)

    const fetchAds = async () => {
      try {
        const response = await api.get(`/products/${route.params.productId}/ads`, {
          params: { 
            page: currentPage.value, 
            per_page: perPage.value, 
            search: search.value,
            sort: sortOrder.value
          }
        })
        ads.value = response.data.ads
        totalPages.value = response.data.pages
      } catch (error) {
        console.error('Error fetching ads:', error)
      }
    }

    const debouncedFetchAds = debounce(fetchAds, 300)

    const handleSearch = () => {
      currentPage.value = 1
      debouncedFetchAds()
    }

    const handleSortChange = () => {
      currentPage.value = 1
      fetchAds()
    }

    const confirmDeleteAd = (adId) => {
      if (confirm('Are you sure you want to delete this ad?')) {
        deleteAd(adId)
      }
    }

    const deleteAd = async (adId) => {
      try {
        await api.delete(`/ads/${adId}`)
        // Refresh the ads list after successful deletion
        fetchAds()
      } catch (error) {
        console.error('Error deleting ad:', error)
        alert('Failed to delete the ad. Please try again.')
      }
    }

    const changePage = (newPage) => {
      currentPage.value = newPage
      fetchAds()
    }

    const goBack = () => {
      router.push('/dashboard')
    }

    const logout = () => {
      localStorage.removeItem('token')
      router.push('/login')
    }

    onMounted(fetchAds)

    watch([search, sortOrder], () => {
      currentPage.value = 1
      fetchAds()
    })

    return {
      ads,
      currentPage,
      totalPages,
      search,
      sortOrder,
      handleSearch,
      handleSortChange,
      changePage,
      goBack,
      deleteAd,
      confirmDeleteAd,
      logout
    }
  }
}
</script>